import React from "react"
import YoutubeBackground from "react-youtube-background"



const YoutubeEl = () => {

    return (
        <YoutubeBackground
            className="yt"
            videoId={"oSPYBnZvQFc"}
            playerOptions={{ mute: 1, loop: 12, autoplay: 1 }}
        >
        </YoutubeBackground>
    )

}

export default YoutubeEl