import React, { useEffect, useState } from "react";
import ImageFadeIn from "react-image-fade-in";
import Skelet from "../components/fotoSkelet";
import Layout from "../components/layout";
import VideoSkelet from "../components/videoSkelet";
import Marta from "../../static/images/marta.webp"
import { Helmet } from "react-helmet";

const Uvod = () => {

  const [youtube, setYoutube] = useState(null)

  useEffect(() => {
    const YoutubeEl = require('../components/youtube').default
    setYoutube(<YoutubeEl />)
  }, [])


  const image = (props) => (
    <a href="https://www.instagram.com/martin___cz/" target="_blank" rel="noreferrer" aria-label={`Instagram`} className='ig-href'>
      <ImageFadeIn
        style={{ width: '100%' }}
        opacityTransition={1.5}
        src={props.src}
        alt={props.alt}
      />
    </a>
  );

  return (
    <Layout>
        <Helmet>
          <title>Úvod | Martin Petrovický | Foto a video</title>
          <meta name="description" content="Zabívám se tvorbou videii a focením všech možných událostí. Na mých stránkách si můžete prohlednou ukázku mé tvorby, přečíst reference či mě rovnou kontaktovat."></meta>
      </Helmet>
      <main>
        <div className="videocontainer">
          {youtube}
        </div>
        <div id="svatby_header text-secondary">
          <div className="container">
            <div className="w-100">
              <h1 className="text-center mt-5">PORTFOLIO</h1>
              <hr className="hr-thickness" />
            </div>


            <Skelet photoList={photoList} />

            <VideoSkelet videos={videos} />

            <div className="row mt-5 py-5 d-flex align-items-center">
              <div className="col-12 col-lg-5 px-0">
                <ImageFadeIn
                  opacityTransition={1.5}
                  className="w-100"
                  src={Marta}
                  alt="martin petrovický - fotka"
                />
              </div>

              <div className="col-12 col-lg-7 py-0 py-lg-3 px-0" style={{ backgroundColor: '#FBFAF7' }}>
                <div className=" mx-3 ps-3 py-3 " style={{ fontSize: '125%', borderLeft: '0.5px solid #292b2c' }}>
                  <p><b>
                    Ukázku mé práce už jste viděli a nyní vám řeknu pár vět o sobě.
                  </b>
                  </p>
                  <p>
                    Jmenuju se Martin a jsem fotograf a videomaker.
                  </p>
                  <p>
                    Při studiu jsem měl možnost zúčastnit se stáže jako kameraman a střihač ve východočeské televizi. Tato profese mě nadchla a začal jsem zde pracovat. Celé 2 roky jsem se podílel na tvorbě zajímavého obsahu do vašich televizních obrazovek, jak v ČR, tak v zahraničí
                    až do chvíle, co jsem natočil své první svatební video, kde jsem mohl být kreativní a zároveň potkat spoustu skvělých lidí. Byla to pro mě nová zkušenost, kterou jsem si zamiloval. Kromě svatebního videa Vám rád natočím i maturitní ples či promo video vaší firmy.
                  </p>
                  <p>
                    Když zrovna nenatáčím můžete mě potkat i s foťákem v ruce. Jsem rád, když mohu na svatbě kameru vyměnit za fotoaparát a odvyprávět váš příběh trochu jinak. Rád Vám udělám rodinné fotky, foto/video nemovitosti, nebo třeba fotky z maturáku.
                  </p>
                  <p>
                    Jestli se vám má práce líbí, napište mi přes kontaktní formulář, nebo na sociálních sítích Instagram a Facebook. Rád se stanu Vaším  fotografem/kameramanem.
                  </p>
                </div>
              </div>
              <div className="w-100 mt-0 mt-md-5 py-0 py-md-5 m-0 text-center">
                <div className="row w-100 pb-0 pb-md-0 mt-4 mx-0" style={{ fontSize: '120%', borderBottom: '0.003em solid #212529' }}>
                  <div className="col-12 col-lg-4 my-2 my-lg-0">
                    <p style={{ fontSize: '120%' }}>
                      Napište mi
                    </p>
                    <p>
                      info@martinpetrovicky.cz
                    </p>
                  </div>
                  <div className="col-12 col-lg-4 ">
                    <p style={{ fontSize: '120%' }}>
                      Zavolejte mi
                    </p>
                    <p>
                      +420 777 668 104
                    </p>
                  </div>
                  <div className="col-12 col-lg-4 my-2 my-lg-0">
                    <p style={{ fontSize: '120%' }}>
                      Facebook
                    </p>
                    <p>
                      Martin Petrovický - Photo & Video
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-0 mx-lg-5 px-0 px-xl-5 instagram-follow">
            <div className="mb-5 mx-0 mx-lx-5 px-0  px-xl-5">
              <p style={{ fontSize: '140%' }} className="text-center mb-5 text-uppercase bold-text">Sledujte moji tvorbu také na instagramu!</p>
              <div className="row my-3 mx-auto px-auto align-items-start align-items-md-center" >
                {image(ig[0])}
                {image(ig[1])}
                {image(ig[2])}
                {image(ig[3])}
                {image(ig[4])}
              </div>
            </div>
          </div >
        </div>

      </main >
    </Layout>
  );
};



const ig = [
  {
    id: 1,
    alt: "moje tvorba na istagramu - italie",
    src: require('../../static/images/instagram/italie.webp').default,

  },
  {
    id: 2,
    alt: "moje tvorba na istagramu - dron",
    src: require('../../static/images/instagram/dron.webp').default,
  },
  {
    id: 3,
    alt: "moje tvorba na istagramu - já s bráchou",
    src: require('../../static/images/instagram/ja.webp').default,
  },
  {
    id: 4,
    alt: "moje tvorba na istagramu - pes",
    src: require('../../static/images/instagram/pes.webp').default,
  },
  {
    id: 5,
    alt: "moje tvorba na istagramu - nemecko",
    src: require('../../static/images/instagram/nemecko.webp').default,
  },
]

const videos = [
  {
    id: 1,
    style: "col-12 col-lg-4",
    preview: "index/videoN1.webp",
    full: "https://www.youtube.com/watch?v=Tu9Rd6EUGXc",
    alt: "ukázka videa - svatba",
    desc: "Svatební video | Bára + Sanny | Svatba na horách",
  },
  {
    id: 2,
    style: "col-12 col-lg-4",
    preview: "index/videoN2.webp",
    full: "https://www.youtube.com/watch?v=apQKqAmxSIs",
    alt: "",
    desc: "Maturitní ples | SOŠPg Nová Paka",
  },
  {
    id: 3,
    style: "col-12 col-lg-4",
    preview: "index/videoN3.webp",
    full: "https://www.youtube.com/watch?v=3QHWpV-Sqls",
    alt: "ukázka reklamního videa lékařské fakulty hradec králové",
    desc: "Reklamní video | Lékařská  univerzita Hradec Králové | ENG ",
  }
];

const photoList = [
  {
    id: 1,
    style: "col-4",
    preview: "index/n/1.webp",
    full: "index/f/1.jpg",
    alt: "prvni fotka",
  },
  {
    id: 2,
    style: "col-4",
    preview: "index/n/2.webp",
    full: "index/f/2.jpg",
    alt: "prvni fotka",
  },
  {
    id: 3,
    style: "col-4",
    preview: "index/n/3.webp",
    full: "index/f/3.jpg",
    alt: "prvni fotka",
  },
  {
    id: 4,
    style: "col-12",
    preview: "index/n/4.webp",
    full: "index/f/4.jpg",
    alt: "prvni fotka",
  },
  {
    id: 5,
    style: "col-12",
    preview: "index/n/5.webp",
    full: "index/f/5.jpg",
    alt: "prvni fotka",
  },
  {
    id: 6,
    style: "col-12",
    preview: "index/n/6.webp",
    full: "index/f/6.jpg",
    alt: "prvni fotka",
  },
  {
    id: 7,
    style: "col-12",
    preview: "index/n/7.webp",
    full: "index/f/7.jpg",
    alt: "prvni fotka",
  },
  {
    id: 8,
    style: "col-12",
    preview: "index/n/8.webp",
    full: "index/f/8.jpg",
    alt: "prvni fotka",
  },
  {
    id: 9,
    style: "col-12",
    preview: "index/n/9.webp",
    full: "index/f/9.jpg",
    alt: "prvni fotka",
  },
  {
    id: 10,
    style: "col-12",
    preview: "index/n/10.webp",
    full: "index/f/10.jpg",
    alt: "prvni fotka",
  },
  {
    id: 11,
    style: "col-12",
    preview: "index/n/11.webp",
    full: "index/f/11.jpg",
    alt: "prvni fotka",
  },
  {
    id: 12,
    style: "col-12",
    preview: "index/n/12.webp",
    full: "index/f/12.jpg",
    alt: "prvni fotka",
  },
  {
    id: 13,
    style: "col-12",
    preview: "index/n/13.webp",
    full: "index/f/13.jpg",
    alt: "prvni fotka",
  },
  {
    id: 14,
    style: "col-12",
    preview: "index/n/14.webp",
    full: "index/f/14.jpg",
    alt: "prvni fotka",
  }
];


export default Uvod;
