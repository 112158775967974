import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import ImageFadeIn from "react-image-fade-in";

const VideoSkelet = (props) => {


    const [isVisibleVideo, setIsVisibleVideo] = useState(false);
    const [video, setVideo] = useState(0);

    const showVideo = (video) => {
        setIsVisibleVideo(!isVisibleVideo);
        setVideo(video);
    };

    const videoEl = (props) => (
        <div key={props.id} className={props.style + " py-1 py-lg-3 px-1 px-lg-3"}>
            <ImageFadeIn
                opacityTransition={1.5}
                className="w-100"
                // src={props.preview}
                // src={require(`../../../static/images/${props.preview}`).default}
                src={require(`../../static/images/${props.preview}`).default}
                alt={props.alt}
                onClick={() => showVideo(props.id)}
            />
            <p style={{ fontSize: '140%' }} className="text-center mt-3">{props.desc}</p>
        </div>
    );


    return (

        <div>
            <FsLightbox
                toggler={isVisibleVideo}
                slide={video}
                sources={props.videos.map((item) => item.full)}
            />

            <div className="row mt-5 pt-5">
                {props.videos.map((item => {
                    return videoEl(item);
                }))}
            </div>
        </div>
    )
};

export default VideoSkelet;
